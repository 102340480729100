"use client";

import clsx from "clsx";
import { Banner } from "flowbite-react";
import posthog from "posthog-js";
import { createContext, useContext, useEffect, useState } from "react";
import { BannerItem, PostHogBannerItem } from "@/types/common/banner";
import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import { AgencyStatusResponse } from "@/types/agencies";
import { Modal } from "flowbite-react";
import { SubscriptionStatus } from "@/enums/stripe";
import FancyLoader from "@/components/common/fancyLoader";
import { api, apiLive } from "@/utils/common/api";
import React from "react";
import { HandleOpenBillingSession } from "@/utils/agencies/settings";
import Button from "@/components/library/Button";
import Icon from "@/components/library/Icon";
import { BANNER_CONDITIONS } from "@/enums/banner";
import { useAlert } from "./useAlert";
import { useProfile } from "./useProfile";

type PricingTableProps = {
  "pricing-table-id": string;
  "publishable-key": string;
  "customer-session-client-secret": string;
};

const StripePricingTable = (props: PricingTableProps) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": props["pricing-table-id"],
    "publishable-key": props["publishable-key"],
    "customer-session-client-secret": props["customer-session-client-secret"],
  });
};

type DiscountType = {
  code: string;
  discount: string;
};

type GenericModalProps = {
  open: boolean;
  message: string;
  description?: string;
};

type BannerProps = {
  bannerItems: BannerItem[];
  setBannerItems: (items: BannerItem[]) => void;
};

const getIcon = (
  status: "success" | "error" | "warning" | "info"
):
  | "check-circle"
  | "circle-exclamation"
  | "triangle-exclamation"
  | "circle-info" => {
  switch (status) {
    case "success":
      return "check-circle";
    case "error":
      return "circle-exclamation";
    case "warning":
      return "triangle-exclamation";
    case "info":
      return "circle-info";
  }
};

let hasShownSubscriptionModal = false;

export const BannerContext = createContext<BannerProps>({
  bannerItems: [],
  setBannerItems: () => {},
});

export const useBanner = () => useContext(BannerContext)!;

const useBannerFunction = (): BannerProps => {
  const { org } = useProfile();

  const [bannerItems, setBannerItems] = useState<BannerItem[]>([]);

  useEffect(() => {
    if (window.localStorage) {
      posthog.onFeatureFlags(async function () {
        if (posthog.isFeatureEnabled("ui-banner")) {
          const bannerItems: BannerItem[] = posthog.getFeatureFlagPayload(
            "ui-banner"
          ) as PostHogBannerItem[];

          const alreadyAckedIds = window.localStorage
            .getItem("GAIL_banner_ids")
            ?.split(",");

          //remove any banners that have already been seen
          let filteredBanners = bannerItems.filter(
            (banner) => !alreadyAckedIds?.includes(banner.id)
          );

          //Check for conditions
          const hasConditions = filteredBanners.some(
            (banner) => banner.condition
          );

          if (hasConditions) {
            let showMigration = false;

            if (
              org &&
              org.features &&
              !org.features.campaignVersion.includes("2")
            ) {
              showMigration = true;
            }

            filteredBanners = bannerItems.filter((banner) => {
              if (!banner.condition) {
                return true;
              }

              switch (banner.condition) {
                case BANNER_CONDITIONS.MIGRATION:
                  return showMigration;
              }
            });
          }

          //sort the banners by status having error, warning, info, success in that order
          const sortedBanners = filteredBanners.sort((a, b) => {
            if (a.status === "error" && b.status !== "error") {
              return -1;
            }

            if (
              a.status === "warning" &&
              b.status !== "error" &&
              b.status !== "warning"
            ) {
              return -1;
            }

            if (a.status === "info" && b.status === "success") {
              return -1;
            }

            return 1;
          });

          setBannerItems(sortedBanners);
        }
      });
    }
  }, [org]);

  return {
    bannerItems,
    setBannerItems,
  };
};

export const BannerContextProvider = ({
  children,
  isAdmin,
}: {
  children: React.ReactNode;
  isAdmin: boolean;
}) => {
  const banner = useBannerFunction();
  const { subscription, getToken } = useAuthContext();
  const { setAlert, setIsLoading } = useAlert();
  const { saveOrg } = useProfile();

  const { bannerItems, setBannerItems } = banner;

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState({
    show: false,
    clientSecret: "",
  });
  const [items, setItems] = useState<BannerItem[]>([]);
  const [discount, setDiscount] = useState<DiscountType | null>(null);

  const openSubscriptionModal = async (source: "timer" | "click") => {
    if (window.location.pathname.includes("/admin")) {
      return;
    }

    if (hasShownSubscriptionModal && source === "timer") {
      return;
    }

    if (source === "timer") {
      hasShownSubscriptionModal = true;
    }

    setIsLoading(
      true,
      "Starting Subscription",
      "Please wait while we start your subscription..."
    );

    //Call the API to start the stripe creation process
    const response = await apiLive<{
      clientSecret: string;
      success: boolean;
    }>(getToken, "/api/billing/create");

    //if successful route to the billing page in a new tab
    //if failed show an error message as alert
    if (response && response.success) {
      setShowSubscriptionModal({
        show: true,
        clientSecret: response.clientSecret,
      });

      posthog.capture(`SubscriptionModalOpen`);
    } else {
      setAlert({
        status: "error",
        message: "Failed to start subscription",
        secondsToClose: 10,
      });
    }

    setIsLoading(false, "");
  };

  const getBannerItemFromSubscription = (
    status: AgencyStatusResponse
  ): BannerItem | null => {
    if (!status || status.status === "active") {
      return null;
    }

    if (status.status === SubscriptionStatus.none) {
      return {
        id: "free-trial",
        status: "info",
        description: "Your account is in limited trial mode.",
        icon: "circle-info",
        isPersistent: true,
        linkAction: async () => {
          openSubscriptionModal("click");
        },
        linkText: "Upgrade to unlock all features!",
      };
    } else if (status.status === SubscriptionStatus.trialing) {
      posthog.capture(`Trialing`, {
        trialDaysLeft: status.trialDaysLeft,
      });

      if ((status.trialDaysLeft ?? 0) > 3) {
        return null;
      }

      return {
        id: "trialing",
        status: "warning",
        description:
          status.trialDaysLeft ?? 0 > 0
            ? `Your trial is ending in ${status.trialDaysLeft ?? 0} days`
            : "Your trial is ending tonight!",
        icon: "circle-info",
        isPersistent: true,
        link: "/settings",
        linkText: "View Billing",
      };
    }

    return {
      id: "canceled",
      status: "error",
      description:
        "Your subscription has ended, we would love to have you back!",
      icon: "circle-exclamation",
      isPersistent: true,
      link: "/settings",
      linkText: "Update Billing",
    };
  };

  useEffect(() => {
    if (subscription.status === SubscriptionStatus.none) {
      const timer = setTimeout(() => {
        openSubscriptionModal("timer");
      }, 120000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [subscription]);

  useEffect(() => {
    if (subscription.flags.needs_signing) {
      setShowTermsModal(true);
    }
  }, [subscription]);

  useEffect(() => {
    if (isAdmin) {
      setItems([
        {
          id: "admin",
          status: "error",
          description: "You are on the Admin Dashboard!",
          icon: "user-crown",
          isPersistent: true,
        },
      ]);
      return;
    }

    const subscriptionBannerItem = getBannerItemFromSubscription(subscription);

    if (subscriptionBannerItem && subscriptionBannerItem.status === "error") {
      setShowBillingModal(true);
    }

    setItems([
      ...bannerItems,
      ...(subscriptionBannerItem ? [subscriptionBannerItem] : []),
    ]);
  }, [subscription, bannerItems, isAdmin]);

  useEffect(() => {
    posthog.onFeatureFlags(function () {
      if (posthog.isFeatureEnabled("ui-discount-code")) {
        const discountData: DiscountType = posthog.getFeatureFlagPayload(
          "ui-discount-code"
        ) as DiscountType;

        setDiscount(discountData);
      }
    });
  }, []);

  return (
    <BannerContext.Provider value={banner}>
      {items.length > 0 ? (
        <Banner key={items[0].id}>
          <div
            className={clsx(
              "flex",
              "w-full",
              "justify-between",
              "p-4",
              "md:ml-[-1rem]",
              "w-auto",
              "relative",
              "z-30",
              {
                "bg-panel dark:bg-gray-700": items[0].status === "info",
                "bg-orange-50 dark:bg-yellow-900":
                  items[0].status === "warning",
                "bg-red-50 dark:bg-red-800": items[0].status === "error",
                "bg-green-50 dark:bg-green-900": items[0].status === "success",
              }
            )}
          >
            <div className={clsx("mx-auto", "flex", "items-center")}>
              <p
                className={clsx(
                  "flex",
                  "items-center",
                  "text-sm",
                  "font-normal",
                  "text-800"
                )}
              >
                <Icon icon={items[0].icon} size={2} className={clsx("mr-3")} />
                <span className="[&_p]:inline">
                  {items[0].description}
                  {(items[0].link || items[0].linkId || items[0].linkAction) &&
                    items[0].linkText && (
                      <a
                        id={items[0].linkId || "bannerLink"}
                        href={items[0].link}
                        onClick={items[0].linkAction || (() => {})}
                        className={clsx(
                          "decoration-600",
                          "inline",
                          "font-medium",
                          "text-button_primary",
                          "underline",
                          "decoration-solid",
                          "underline-offset-2",
                          "ml-2",
                          "cursor-pointer",
                          "hover:no-underline"
                        )}
                        target={
                          items[0].link?.startsWith("http") ? "_blank" : "_self"
                        }
                      >
                        {items[0].linkText}
                      </a>
                    )}
                </span>
              </p>
            </div>
            {!items[0].isPersistent && (
              <Banner.CollapseButton
                color="orange"
                className={clsx("border-0", "bg-transparent", "text-800")}
                onClick={() => {
                  const ackedIds =
                    window.localStorage.getItem("GAIL_banner_ids");
                  if (ackedIds) {
                    window.localStorage.setItem(
                      "GAIL_banner_ids",
                      `${ackedIds},${items[0].id}`
                    );
                  } else {
                    window.localStorage.setItem("GAIL_banner_ids", items[0].id);
                  }
                  setBannerItems(items.slice(1));
                }}
              >
                <Icon icon="close" />
              </Banner.CollapseButton>
            )}
          </div>
        </Banner>
      ) : null}
      {children}
      <Modal
        id="terms-modal"
        title="Please Read and Accept Terms"
        show={showTermsModal}
      >
        <Modal.Header className={clsx("[&_button]:hidden")}>
          <h2>Please Read and Accept the T&Cs</h2>
        </Modal.Header>
        <Modal.Body>
          <p>
            We are so excited to have you onboard! Please read and accept the
            terms and conditions to continue.
          </p>
          <p className={clsx("mt-4")}>
            Please review the{" "}
            <a
              href="https://www.lula.com/terms-and-conditions"
              target="_blank"
              className={clsx("text-primary")}
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
            . By agreeing you also authorize communications from us via SMS and
            email.
          </p>
          <p className={clsx("mt-4")}>
            Please review the{" "}
            <a
              href="https://www.lula.com/privacy-policy"
              target="_blank"
              className={clsx("text-primary")}
            >
              Privacy policy
            </a>{" "}
            for more information.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className={clsx("w-full")}>
            <Button
              type="primary"
              onClick={async () => {
                setShowTermsModal(false);

                await saveOrg({
                  details: {
                    needs_signing: false,
                  },
                });
              }}
              className={clsx("float-right", "ml-4")}
            >
              I Agree
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        id="account-not-active-modal"
        title="Account no Longer Active"
        onClose={() => {
          setShowBillingModal(false);
        }}
        show={showBillingModal}
      >
        <Modal.Header>
          <h2>Your Account is no Longer Active</h2>
        </Modal.Header>
        <Modal.Body>
          <p>
            We would love to have you stay onboard! please update your billing
            information to continue use of GAIL.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className={clsx("w-full")}>
            <Button
              type="primary"
              onClick={() => {
                setShowBillingModal(false);
                HandleOpenBillingSession(getToken, setAlert);
              }}
              className={clsx("float-right", "ml-4")}
            >
              <Icon icon="address-card" className={clsx("mr-2")} />
              <span>Update Billing</span>
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        id="subscription-modal"
        title="Choose Your Subscription"
        show={showSubscriptionModal.show}
        onClose={() => {
          setShowSubscriptionModal({
            show: false,
            clientSecret: showSubscriptionModal.clientSecret,
          });
        }}
        size={"4xl"}
      >
        <Modal.Header>
          <h2>Ready to Purchase?</h2>
        </Modal.Header>
        <Modal.Body>
          <div
            className={clsx(
              "mb-6",
              "grid",
              "gap-4",
              "grid-cols-1",
              "md:grid-cols-2"
            )}
          >
            <div className={clsx("max-w-sm", "mx-auto")}>
              <span className={clsx("mb-2", "text-lg", "block")}>You Can:</span>
              <ul
                className={clsx(
                  "list-disc",
                  "mx-auto",
                  "inline-block",
                  "mb-4",
                  "list-inside"
                )}
              >
                <li>Continue trying GAIL</li>
                <li>Book a demo</li>
                <li className={clsx("font-semibold")}>
                  Purchase and Schedule Onboarding
                </li>
              </ul>
            </div>
            <div className={clsx("max-w-sm", "mx-auto")}>
              We are excited you have decided to try GAIL. If you are ready to
              purchase we would love to help with your onboarding.
              {discount && (
                <>
                  <h3
                    className={clsx("mt-4", "mb-2", "text-lg", "font-semibold")}
                  >
                    We have a discount for you!
                  </h3>
                  <p>
                    Use Code{" "}
                    <span className={clsx("text-xl", "font-semibold")}>
                      {discount.code}
                    </span>{" "}
                    for{" "}
                    <span className={clsx("text-xl", "font-semibold")}>
                      {discount.discount}
                    </span>{" "}
                    off your first month
                  </p>
                </>
              )}
            </div>
          </div>
          <div className={clsx("grid", "checkout_container")}>
            {showSubscriptionModal.clientSecret ? (
              <StripePricingTable
                pricing-table-id={
                  process.env.NEXT_PUBLIC_STRIPE_PRICING_TABLE_ID || ""
                }
                publishable-key={
                  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || ""
                }
                customer-session-client-secret={
                  showSubscriptionModal.clientSecret
                }
              />
            ) : (
              <FancyLoader />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="secondary"
            onClick={() =>
              setShowSubscriptionModal({
                show: false,
                clientSecret: showSubscriptionModal.clientSecret,
              })
            }
          >
            Continue Trying GAIL
          </Button>
        </Modal.Footer>
      </Modal>
    </BannerContext.Provider>
  );
};
